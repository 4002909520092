// Imports
// ------
import React from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
const Pre = () => {
	const bp = useBreakpoint();

	return <>{bp.medium ? <Mobile /> : <Desktop />}</>;
};

export default Pre;
