// Imports
// ------
import React, { useRef, useState } from 'react';
import Icon from '@icons';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Row, Column } from '@waffl';
import { Wrapper } from '@tackl';

// Styles
// ------
import {
	Jacket,
	Content,
	Heading,
	Email,
	EmailInput,
	EmailSubmit,
	EmailMessage,
	CloseMessage,
} from './styles';

// Component
// ------
const CustomForm = ({ status, message, onValidated }) => {
	const [active, setActive] = useState(false);

	const emailMessage = useRef();

	let email;

	const handleMessageClose = () => {
		emailMessage.current.style.transform = `translateY(100%) translateX(-50%)`;
	};

	const handleMessageOpen = () => {
		emailMessage.current.style.transform = `translateY(0%) translateX(-50%)`;
	};

	const handleChange = (e) => {
		setActive(e.target.value.length > 0);
	};

	const submit = () => {
		email &&
			email.value.indexOf('@') > -1 &&
			onValidated({
				EMAIL: email.value,
			});

		handleMessageOpen();
	};

	return (
		<Email isActive={active}>
			<EmailMessage
				ref={emailMessage}
				success={status === 'success'}
				error={status === 'error'}
				sending={status === 'sending'}>
				{status === 'sending' && `Sending...`}
				{status === 'error' && message}
				{status === 'success' && message}

				<CloseMessage type='button' onClick={handleMessageClose}>
					<Icon type='close' />
				</CloseMessage>
			</EmailMessage>

			<EmailInput
				ref={(node) => (email = node)}
				type='email'
				placeholder='Email Address'
				onChange={handleChange}
			/>

			<span>Email Address</span>

			<EmailSubmit onClick={submit}>
				<Icon type='arrow-right' />
			</EmailSubmit>
		</Email>
	);
};

const Newsletter = () => {
	const url =
		'https://m-xr.us19.list-manage.com/subscribe/post?u=8772859d5e963529a2a10294a&amp;id=c8b5a09607&amp;f_id=009aa5e4f0';

	return (
		<Jacket>
			<Wrapper>
				<Row isExpanded sidePad isCenter isCollapsed>
					<Column small={12} mpad>
						<Content>
							<Heading weight='semi'>subscribe for updates</Heading>

							<MailchimpSubscribe
								url={url}
								render={({ subscribe, status, message }) => (
									<CustomForm
										status={status}
										message={message}
										onValidated={(formData) => subscribe(formData)}
									/>
								)}
							/>
						</Content>
					</Column>
				</Row>
			</Wrapper>
		</Jacket>
	);
};

export default Newsletter;
