// Imports
// ------
import React from 'react';
import Social from '@parts/Social';
import Copyright from '@parts/Copyright';
import { Row, Column } from '@waffl';
import { Wrapper } from '@tackl';

// Styles
// ------
import { Jacket, Legal, SocialContainer } from './styles';

// Component
// ------
const Post = () => (
	<Jacket>
		<Wrapper className="has-columns">
			<Row isExpanded sidePad isCenter className='row'>
				<Column small={12} large={6} mpad>
					<Legal>
						<Copyright />
					</Legal>
				</Column>

				<Column small={12} large={6} mpad className='social'>
					<SocialContainer>
						<Social />
					</SocialContainer>
				</Column>
			</Row>
		</Wrapper>
	</Jacket>
);

export default Post;
