// Imports
// ------
import React from 'react';
import Pre from './Pre';
import Post from './Post';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Footer = () => (
	<Jacket>
		<Pre />
		<Post />
	</Jacket>
);

export default Footer;
