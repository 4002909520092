// Imports
// ------
import styled, { css } from 'styled-components';
import { Section } from '@tackl';
import { pStyles } from '@type';
import TransitionLink from 'gatsby-plugin-transition-link';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) =>
		css`
			background: ${props.theme.black};
		`
);

export const Content = styled.ul(
	(props) => css`
		padding: 2.4rem 0 3.6rem 0;

		> li {
			padding: 1.2rem 0;

			> ul {
				> li {
					padding: 1.2rem 2.4rem;

					&:first-child {
						padding-top: 2.4rem;
					}
				}
			}
		}
	`
);

const sharedLinkStyles = (props) => css`
	${pStyles}
	letter-spacing: 1px;
	font-weight: 400;
	padding: 0.6rem 0;
	font-size: 1.6rem;
`;

export const PageLink = styled(TransitionLink)(
	(props) => css`
		${sharedLinkStyles}
	`
);

export const FakePageLink = styled.span(
	(props) => css`
		${sharedLinkStyles}

		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			fill: ${props.theme.white};
			width: 1.8rem;
			height: 1.8rem;
			transition: ${props.theme.ease};
			transform: rotate(${props.isOpen ? -90 : 0}deg);
		}
	`
);

export const Submenu = styled.ul(
	(props) => css`
		max-height: ${props.isOpen ? 500 : 0}px;
		transition: max-height 0.5s ${props.isOpen ? `ease-in` : `ease-out`};
		overflow: hidden;

		> li {
			opacity: 0.6;
		}
	`
);
