// Imports
// ------
import styled, { css } from 'styled-components';
import { Section, Em, P } from '@tackl';
import { emStyles } from '@type';
import { Link } from 'gatsby';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) =>
		css`

		`
);

export const LogoWrapper = styled.div(
	(props) =>
		css`
			width: 6rem;
			height: 6rem;
			background-color: #101010;
			border-radius: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 3.5rem;

			svg {
				width: 3.3rem;
			}
		`
);

export const Content = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: flex-start;

		&.address-field {
			text-align: right;
			align-items: flex-end;
		}

		&.links-field {
			ul {
				margin-left: auto;
			}
		}

		ul {
			display: flex;
			flex-flow: column;
			gap: 3.7rem;

			li,
			li a {
				font-family: 'Kanit';
				display: inline-block;
				color: ${props.theme.white};
				transition: ${props.theme.ease};
				font-size: 1.8rem !important;
				font-weight: 400;
			}

			li a {
				&:hover {
					color: ${props.theme.bc1};
				}
			}
		}
	`
);

export const Heading = styled(P)(
	(props) => css`
		font-family: 'Kanit';
		display: inline-block;
		color: ${props.theme.white};
		transition: ${props.theme.ease};
		font-size: 1.8rem !important;
		font-weight: 400;
	`
);

export const Description = styled(P)(
	(props) => css`
		font-size: 1.6rem;
		font-weight: 300;
		color: #929292;
		line-height: 1.2em;
		max-width: 39rem;
	`
);

export const HeadingLink = styled.a(
	(props) => css`
		display: block;
		transition: ${props.theme.ease};

		&:hover {
			color: ${props.theme.bc1};
		}
	`
);

export const GoHome = styled(Link)(
	(props) => css`
		display: inline-flex;

		svg {
			transition: ${props.theme.ease};
		}

		&:hover svg {
			fill: ${props.theme.bc1};
		}
	`
);

export const Address = styled.ul(
	(props) => css`
		display: flex;
		gap: 1.2rem;
		flex-flow: column;
		align-items: flex-start;
		margin-left: 0;

		li.address {
			font-weight: ${props.theme.light};
			line-height: 1.5em !important;
			color: #3F1FFF;
			font-size: 1.6rem !important;
		}
	`
);

export const Email = styled.a(
	(props) => css`
		position: relative;
		display: inline-block;
		font-weight: ${props.theme.bold};
		margin-left: auto;
		margin-top: 3.6rem;
		padding-bottom: 0.2rem;

		&:hover {
			&:after {
				transform: scaleX(0);
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background: ${props.theme.white};
			transition: ${props.theme.ease};
			transform-origin: center right;
		}
	`
);

export const LegalLinks = styled.ul(
	(props) => css`
		display: flex;
		flex-direction: row !important;
		margin-top: 4rem;
	`
);

export const LegalLink = styled.li(
	(props) => css`
		a {
			font-weight: 400 !important;
			position: relative;
			display: inline-block;
			cursor: pointer;
			color: #929292 !important;
			transition: ${props.theme.ease};

			&:hover {
				color: ${props.theme.bc1};
			}
		}
	`
);
