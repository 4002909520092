// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H4, Em, grad } from '@tackl';
import { emStyles, pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)((props) => css`
	position: relative;
	z-index: 2;
	background: ${props.theme.bc3};
`);

export const Content = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: space-between;

		padding: 3.6rem 2.4rem;
		border-radius: 2px;

		${breakup.large`
			flex-flow: row;
			align-items: center;
			padding: 3.6rem 0;
		`}
	`
);

export const Heading = styled(H4)(
	(props) => css`
		margin: 0 0 3.6rem;
		font-size: 2.2rem;

		${breakup.large`
			margin: 0 auto 0 0;
		`}
	`
);

export const Email = styled.div(
	(props) => css`
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		margin-left: auto;
		padding: 1.2rem 0;
		border-bottom: 1px solid ${props.theme.white};
		width: 100%;
		max-width: 45.8rem;

		span {
			${emStyles}
			width: 100%;
			display: flex;
			align-items: center;

			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
			padding: 1.2rem 0;
			color: ${props.theme.white};
			transition: ${props.theme.ease};
			pointer-events: none;
			font-weight: 300;
			letter-spacing: 1px;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100%;
				transform: scaleY(0);
				transform-origin: center bottom;
				background: ${props.theme.white};
				transition: ${props.theme.ease};
			}
		}

		input {
			&:focus,
			&:active {
				outline: none;
				text-indent: 1.2rem;

				&::placeholder {
					color: ${props.theme.black};
					text-indent: 1.2rem;
				}

				+ span {
					padding-left: 1.2rem;

					&:after {
						transform: scaleY(1);
						padding-left: 1.2rem;
					}
				}
			}
		}

		// If text is entered
		${props.isActive &&
		css`
			input {
				text-indent: 1.2rem;
			}

			span {
				&:after {
					transform: scaleY(1);
				}

				button {
					transform: translateX(-1.2rem);

					svg {
						fill: ${props.theme.black};
					}
				}
			}
		`}
	`
);

export const EmailInput = styled.input(
	(props) => css`
		${emStyles}
		position: relative;
		z-index: 4;
		color: ${props.theme.black};

		width: calc(100% - 3.6rem);
		background: transparent;
		padding: 0;
		

		${breakup.large` font-size: 1.4rem; `}

		&:active,
    	&:focus {
			&::placeholder {
				color: ${props.theme.bc3};
			}
		}

		&::placeholder {
			${emStyles}
			color: transparent;
			transition: ${props.theme.ease};
		}
	`
);

export const EmailSubmit = styled.button(
	(props) => css`
		position: absolute;
		z-index: 5;
		right: 0;
		background: transparent;
		padding: 1.2rem;
		cursor: pointer;
		transition: ${props.theme.ease};
		mix-blend-mode: difference;

		svg {
			fill: ${props.theme.white};
			width: 1.8rem;
			height: 1rem;
			transition: ${props.theme.ease};
			transform: translateY(0.3rem);
		}
	`
);

export const EmailMessage = styled(Em)(
	(props) => css`
		${grad}
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: none;

		position: fixed;
		z-index: 10;
		bottom: 0;
		left: 50%;
		width: 100%;
		max-width: calc(100% - 12rem);
		padding: 1.2rem;

		transform: translateY(100%) translateX(-50%);
		transform-origin: center bottom;
		transition: all 1s ${props.theme.bezzy};

		${breakup.large`
			height: 3.6rem;
			padding: 0 1.2rem;
		`}
	`
);

export const CloseMessage = styled.button(
	(props) => css`
		background: transparent;
		cursor: pointer;

		svg {
			width: 1.6rem;
			height: 1.6rem;
			stroke: ${props.theme.white};
			stroke-width: 2px;
		}
	`
);
