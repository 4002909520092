// Imports
// ------
import styled, { css } from 'styled-components';
import { Footer } from '@tackl';

// Exports
// ------
export const Jacket = styled(Footer)(props => css`
    position: relative;
    background: linear-gradient(90deg, #080809 50%, #303339 200%);
`);