// Imports
// ------
import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { Row, Column } from '@waffl';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Wrapper } from '@tackl';
import Logo from '@parts/Logo';

// Styles
// ------
import {
	Jacket,
	Content,
	Heading,
	HeadingLink,
	LegalLinks,
	LegalLink,
	Address,
	Description,
	LogoWrapper,
} from './styles';

function Desktop({ nftsEnabled }) {
	const pageTrans = {
		preventScrollJump: true,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
		},
	};

	return (
		<StaticQuery
			query={graphql`
				query DesktopFooter {
					projects: allDatoCmsProjectsPost(
						sort: { order: ASC, fields: position }
						limit: 5
					) {
						nodes {
							title
							brand
							category
							slug
						}
					}
					nfts: allDatoCmsNftDropsPost(limit: 5) {
						nodes {
							title
							slug
						}
					}
					globalOptions: datoCmsOptionsGlobal {
						addressLine1
						addressLine2
						addressLine3
						email 
						nfts: nftsEnabled
					}
				}
			`}
			render={(d) => (
				<Jacket padSmall>
					<Wrapper className="has-columns">
						<Row isExpanded sidePad className='space-between'>
							<Column small={12} large={7.75} mpad>
								<Content>
									<Link to="/">
										<LogoWrapper>
											<Logo />
										</LogoWrapper>
									</Link>

									<Description>
										Dive into the future of 3D with us. Our goal is to democratise the
										creation of quality 3D content and enable creators to build infinite
										virtual worlds at scale.
									</Description>

									<LegalLinks>
										<LegalLink>
											<TransitionLink to='/legal#privacy' {...pageTrans}>
												Privacy Policy
											</TransitionLink>
										</LegalLink>

										<LegalLink>
											<TransitionLink to='/legal#cookies' {...pageTrans}>
												Cookies
											</TransitionLink>
										</LegalLink>

										<LegalLink>
											<TransitionLink to='/legal/' {...pageTrans}>
												Legal
											</TransitionLink>
										</LegalLink>
									</LegalLinks>
								</Content>
							</Column>

							<Column small={12} large={2} mpad>
								<Content className="links-field">
									<ul>
										<li>
											<TransitionLink to='/marso/' {...pageTrans}>
												Marso
											</TransitionLink>
										</li>
										<li>
											<TransitionLink to='/pricing/' {...pageTrans}>
												Pricing
											</TransitionLink>
										</li>
										<li>
											<TransitionLink to='/blog/' {...pageTrans}>
												Blog
											</TransitionLink>
										</li>
										<li>
											<TransitionLink to='/careers/' {...pageTrans}>
												Careers
											</TransitionLink>
										</li>
									</ul>
								</Content>
							</Column>

							<Column small={12} large={2.25} mpad>
								<Content className="address-field">
									<Heading style={{marginBottom: '2.85rem'}} weight="semi">M–XR Limited</Heading>

									<Address>
										<li className="address">
											203-213 Mare St, <br/>
											London, <br/>
											E8 3LY 
										</li>
									</Address>

									<HeadingLink style={{marginTop: '2.85rem'}} href={`mailto:${d.globalOptions.email}`}>
										<Heading weight="semi">{d.globalOptions.email}</Heading>
									</HeadingLink>
								</Content>
							</Column>
						</Row>
					</Wrapper>
				</Jacket>
			)}
		/>
	);
}

export default Desktop;
