// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { emStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		padding: 0 0 2.9rem 0;
		background: ${props.theme.black};
		position: relative;

		&::before {
			content: '';
			width: 100%;
			height: 1px;
			background: linear-gradient(90deg, rgba(16,16,18,1) 0%, rgba(255,255,255,1) 50%, rgba(16,16,18,1) 100%); 
			display: block;
			max-width: 160rem;
			margin: -1.3rem auto 1.5rem auto;
		}

		${breakup.large`
            background: ${props.theme.black};
            padding: 1.2rem 0;
        `}

		.row {
			flex-flow: column-reverse;
			${breakup.large` flex-flow: row; `}
		}

		.social {
			background: ${props.theme.black};
			margin-bottom: 2.4rem;
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;

			${breakup.large`
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
            `}
		}
	`
);

export const Legal = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: flex-start;
		text-align: center;

		${breakup.large`
			flex-flow: row;
			text-align: left;
		`}
	`
);

export const SocialContainer = styled.div(
	(props) => css`
		display: flex;
		justify-content: center;
		flex: 1;

		${breakup.large`
            justify-content: flex-end;
        `}

		ul {
			margin: 0;
		}
	`
);
