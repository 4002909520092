// Imports
// ------
import React, { useState } from 'react';
import Icon from '@icons';
import { Row, Column } from '@waffl';
import { StaticQuery, graphql } from 'gatsby';

// Styles
// ------
import { Jacket, Content, PageLink, FakePageLink, Submenu } from './styles';

// Component
// ------
const Mobile = () => {
	const [submenu, setSubmenu] = useState(false);

	const handleSubmenu = () => {
		setSubmenu(!submenu);
	};

	const pageTrans = {
		preventScrollJump: true,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
			trigger: ({ node }) => {
				node.style.top = '0px';
			},
		},
	};

	return (
		<StaticQuery
			query={graphql`
				query MobileFooter {
					enable: datoCmsOptionsGlobal {
						nfts: nftsEnabled
					}
				}
			`}
			render={(d) => (
				<Jacket>
					<Row isExpanded>
						<Column small={12} mpad>
							<Content>
								{d.enable.nfts && (
									<li>
										<PageLink {...pageTrans} to='/nfts/'>
											NFT
										</PageLink>
									</li>
								)}

								<li>
									<PageLink {...pageTrans} to='/marso/'>
										Marso
									</PageLink>
								</li>

								<li>
									<PageLink {...pageTrans} to='/blog/'>
										Blog
									</PageLink>
								</li>

								<li>
									<PageLink {...pageTrans} to='/careers/'>
										Careers
									</PageLink>
								</li>

								<li>
									<PageLink {...pageTrans} to='/legal/#privacy'>
										Privacy
									</PageLink>
								</li>

								<li>
									<PageLink {...pageTrans} to='/legal/#cookies'>
										Cookies
									</PageLink>
								</li>

								<li>
									<PageLink {...pageTrans} to='/legal/'>
										Legal
									</PageLink>
								</li>

							</Content>
						</Column>
					</Row>
				</Jacket>
			)}
		/>
	);
};

export default Mobile;
